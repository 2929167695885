<template>
    <div v-html="htmlNew"></div>
</template>

<script>
import api from '/src/services/api';
import authHeader from '/src/services/auth-header'; 

export default {
    
    name: "TinyHTML",
    props: [
        "html",
    ],
    data() {
        return {
            htmlNew: this.html
        }
    },
    created(){
        this.getFileFromAzure();
    },
    methods : {
        getFileFromAzure(){
            let parser = new DOMParser();
            const htmlDom = parser.parseFromString(this.html, "text/html");
            const errorNode = htmlDom.querySelector("parsererror");
            if(!errorNode){
                let imageTags = [...htmlDom.getElementsByTagName("img")];
                Promise.all(
                    imageTags.map(async (e) => {
                        let oldSrc = e.getAttribute("src");
                        if (oldSrc.includes("prismaccess-tinymcefilemanager")) {
                            const indexOfFirst = oldSrc.indexOf("image/");
                            const indexOfLast = oldSrc.indexOf("?");
                            let path = oldSrc.slice(indexOfFirst, indexOfLast);
                            let newPath = await this.getFileByKey(path, "prismaccess-tinymcefilemanager");
                            e.setAttribute("src", newPath);
                        }
                    })
                ).then(() => {
                    this.htmlNew = htmlDom.documentElement.innerHTML;
                });
            } 
        },
        async getFileByKey(key, container) {
            return api
            .get(`generateBlobDownloadLinkWithSAS?file=${key}&container=${container}`, { headers: authHeader() })
            .then(response => {  
                return response.data;
            })
            .catch(error  => {            
                console.error(error);
                return Promise.reject(error);
            });
        }
    }

}
</script>

<style scoped>

</style>
